import { SignUpService } from "@services/signup.service";

interface ISignupData {
    FirstName: string;
    LastName: string;
    Email: string;
    Password: string;
    ConfirmPassword: string;
    CompanyName: string;
    JobTitle: string;
}

class SignupFormService {
    disableEmail: boolean;
    signupData: ISignupData;
    blockedDomains: string[];
    emailRules = [
        (v) => !!v || 'Email is required',
        (v) =>
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                v
            ) || 'E-mail must be valid',
        (v) => !/\s/.test(v) || 'Email cannot contain spaces',
        (v) => (v && v.includes('@') && !this.blockedDomains.includes(v.split('@')[1].toLowerCase())) ||
            'Please use an enterprise email account',
    ];
    passwordRules = [
        (v) => !!v || 'Password is required',
        (v) => (v && v.length >= 8) || 'Must be at least 8 characters',
        (v) => /[A-Z]/.test(v) || 'Must contain at least 1 capital letter',
        (v) => /\d/.test(v) || 'Must contain at least 1 number',
        (v) => /[^A-Za-z 0-9]/.test(v) || 'Must contain special characters', // by checking presence of any  character other than numbers and aplphabets
        (v) => !/\s/.test(v) || 'Cannot contain spaces',
        () => (this.signupData.ConfirmPassword === this.signupData.Password) || 'Passwords do not match'
    ];
    defaultRules = [
        (v) => !!v || 'This field is required'
    ];

    constructor() {
        this.disableEmail = false;
        this.blockedDomains = [];
        this.resetForm();
        this.getBlockedDomains();
    }

    public resetForm() {
        this.signupData = {
            FirstName: '',
            LastName: '',
            Email: '',
            Password: '',
            ConfirmPassword: '',
            CompanyName: '',
            JobTitle: ''
        }

        this.disableEmail = false;
    }

    public pasteEmail(email) {
        this.signupData.Email = email;
        this.disableEmail = true;
    }

    private async getBlockedDomains() {
        try {
            this.blockedDomains = await SignUpService.getBLDomains();
        } catch (error) {
            console.log(error)
        }
    }
}

export default SignupFormService;
