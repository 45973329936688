var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.signupSuccess
      ? _c(
          "div",
          [
            _c("ErrorAlert", {
              attrs: { errorMessage: _vm.signupErrorMessage },
            }),
            _c(
              "v-form",
              {
                ref: "signupFormRef",
                model: {
                  value: _vm.formValid,
                  callback: function ($$v) {
                    _vm.formValid = $$v
                  },
                  expression: "formValid",
                },
              },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "6" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            rules: _vm.signupFormService.defaultRules,
                            label: "First Name",
                            height: "48",
                            "hide-details": "auto",
                            "append-icon": "mdi-check",
                            required: "",
                          },
                          model: {
                            value: _vm.signupFormService.signupData.FirstName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.signupFormService.signupData,
                                "FirstName",
                                $$v
                              )
                            },
                            expression:
                              "signupFormService.signupData.FirstName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "6" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            rules: _vm.signupFormService.defaultRules,
                            label: "Last Name",
                            height: "48",
                            "hide-details": "auto",
                            "append-icon": "mdi-check",
                            required: "",
                          },
                          model: {
                            value: _vm.signupFormService.signupData.LastName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.signupFormService.signupData,
                                "LastName",
                                $$v
                              )
                            },
                            expression: "signupFormService.signupData.LastName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("v-text-field", {
                  attrs: {
                    disabled: _vm.signupFormService.disableEmail,
                    rules: _vm.signupFormService.emailRules,
                    label: "Email",
                    height: "48",
                    "hide-details": "auto",
                    "append-icon": "mdi-check",
                    required: "",
                  },
                  model: {
                    value: _vm.signupFormService.signupData.Email,
                    callback: function ($$v) {
                      _vm.$set(_vm.signupFormService.signupData, "Email", $$v)
                    },
                    expression: "signupFormService.signupData.Email",
                  },
                }),
                _c("v-text-field", {
                  attrs: {
                    rules: _vm.signupFormService.defaultRules,
                    label: "Company Name",
                    height: "48",
                    "hide-details": "auto",
                    "append-icon": "mdi-check",
                    required: "",
                  },
                  model: {
                    value: _vm.signupFormService.signupData.CompanyName,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.signupFormService.signupData,
                        "CompanyName",
                        $$v
                      )
                    },
                    expression: "signupFormService.signupData.CompanyName",
                  },
                }),
                _c("v-text-field", {
                  attrs: {
                    rules: _vm.signupFormService.defaultRules,
                    label: "Job Title",
                    height: "48",
                    "hide-details": "auto",
                    "append-icon": "mdi-check",
                    required: "",
                  },
                  model: {
                    value: _vm.signupFormService.signupData.JobTitle,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.signupFormService.signupData,
                        "JobTitle",
                        $$v
                      )
                    },
                    expression: "signupFormService.signupData.JobTitle",
                  },
                }),
                _c("v-text-field", {
                  attrs: {
                    rules: _vm.signupFormService.passwordRules,
                    type: _vm.passwordInputTypeToggle,
                    height: "48",
                    "hide-details": "auto",
                    "append-icon": _vm.passwordEyeIcon,
                    label: "Password",
                    required: "",
                  },
                  on: {
                    "click:append": function ($event) {
                      return _vm.toggleEyeIcon()
                    },
                    input: function ($event) {
                      return _vm.validateForm()
                    },
                  },
                  model: {
                    value: _vm.signupFormService.signupData.Password,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.signupFormService.signupData,
                        "Password",
                        $$v
                      )
                    },
                    expression: "signupFormService.signupData.Password",
                  },
                }),
                _c("v-text-field", {
                  attrs: {
                    type: _vm.passwordInputTypeToggle,
                    rules: _vm.signupFormService.passwordRules,
                    height: "48",
                    "hide-details": "auto",
                    "append-icon": _vm.passwordEyeIcon,
                    label: "Confirm Password",
                    required: "",
                  },
                  on: {
                    "click:append": function ($event) {
                      return _vm.toggleEyeIcon()
                    },
                    input: function ($event) {
                      return _vm.validateForm()
                    },
                  },
                  model: {
                    value: _vm.signupFormService.signupData.ConfirmPassword,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.signupFormService.signupData,
                        "ConfirmPassword",
                        $$v
                      )
                    },
                    expression: "signupFormService.signupData.ConfirmPassword",
                  },
                }),
                _c(
                  "p",
                  {
                    staticClass:
                      "greybase--text mt-8 font-15 medium-font text-center",
                  },
                  [
                    _vm._v(" By clicking SIGN UP you agree to SAM's"),
                    _c("br"),
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "https://www.kochava.com/legal/license-service-agreement",
                          target: "_blank",
                        },
                      },
                      [_vm._v("terms of service")]
                    ),
                    _vm._v(" and "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "https://www.searchadsmaven.com/website-visitor-privacy-policy",
                          target: "_blank",
                        },
                      },
                      [_vm._v("privacy policy")]
                    ),
                    _vm._v(". "),
                  ]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "mt-1",
                    attrs: {
                      disabled: !_vm.formValid,
                      color: "primary",
                      width: "100%",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.signupOry()
                      },
                    },
                  },
                  [_c("strong", [_vm._v("SIGN UP")])]
                ),
              ],
              1
            ),
          ],
          1
        )
      : _c("div", [
          _c(
            "div",
            { staticClass: "mt-5 d-flex justify-center" },
            [
              _c("SuccessMessage", {
                attrs: { successMessage: _vm.signupSuccessMessage },
              }),
            ],
            1
          ),
          _c(
            "p",
            { staticClass: "font-16 medium-font text-center mt-10" },
            [
              _c(
                "router-link",
                { staticClass: "primary--text", attrs: { to: "/login" } },
                [_vm._v("Go back to login")]
              ),
            ],
            1
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }