var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("SuccessAlert", {
        attrs: { successMessage: _vm.successAlertMessage },
      }),
      _c("ErrorAlert", { attrs: { errorMessage: _vm.loginErrorMessage } }),
      _c(
        "v-form",
        {
          attrs: { action: _vm.action, method: "POST" },
          model: {
            value: _vm.formValid,
            callback: function ($$v) {
              _vm.formValid = $$v
            },
            expression: "formValid",
          },
        },
        [
          _c("input", {
            attrs: { type: "hidden", name: "csrf_token" },
            domProps: { value: _vm.csrf_token },
          }),
          _c("v-text-field", {
            attrs: {
              name: "password_identifier",
              label: "Email",
              height: "56",
              "hide-details": "auto",
              "append-icon": "mdi-check",
              required: "",
              rules: _vm.loginFormService.emailRules,
            },
          }),
          _c("v-text-field", {
            attrs: {
              name: "password",
              type: _vm.passwordInputTypeToggle,
              height: "56",
              "hide-details": "auto",
              "append-icon": _vm.passwordEyeIcon,
              label: "Password",
              required: "",
              rules: _vm.loginFormService.passwordRules,
            },
            on: {
              "click:append": function ($event) {
                return _vm.toggleEyeIcon()
              },
            },
          }),
          _c(
            "v-btn",
            {
              staticClass: "mt-10",
              attrs: {
                disabled: !_vm.formValid,
                value: "password",
                name: "method",
                type: "submit",
                color: "primary",
                width: "100%",
              },
            },
            [_c("strong", [_vm._v("LOGIN")])]
          ),
          _c(
            "p",
            { staticClass: "font-16 gotham-light-font text-center mt-10" },
            [
              _c(
                "router-link",
                {
                  staticClass: "primary--text",
                  attrs: { to: "/signin/recovery" },
                },
                [_vm._v("Forgot your password?")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }