

























// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
import ErrorAlert from '../Utility/ErrorAlert.vue';
import SuccessAlert from '../Utility/SuccessAlert.vue';
import LoginFormService from './services/login-form.service';

import { FlowService } from '@services/flow.service';
import { API_ORY_URL } from '@config';
import { LSS } from '@core/services';

export default Vue.extend({
  name: 'LoginForm',
  components: {
    ErrorAlert,
    SuccessAlert
  },
  data() {
    return {
      loginFormService: null,
      formValid: false,
      eyeToggle: true,
      loginErrorMessage: '',
      successAlertMessage: '',
      // ory fields
      action: null,
      csrf_token: null
    };
  },
  computed: {
    passwordEyeIcon() {
      return this.eyeToggle ? 'mdi-eye' : 'mdi-eye-off';
    },
    passwordInputTypeToggle() {
      return this.eyeToggle ? 'password' : 'text';
    }
  },
  props: {

  },
  methods: {
    toggleEyeIcon() {
      this.eyeToggle = !this.eyeToggle;
    },
    async getFlow() {
      this.fullscreenLoader = this.$loading.show();

      try {
        const flowResponse = await FlowService.fetchFlow(this.$route.query.flow, 'login');

        this.fullscreenLoader.hide();
        if (flowResponse.error) return this._handleFlowError(flowResponse);

        this.csrf_token = flowResponse.ui.nodes[0].attributes.value;
        this.action = flowResponse.ui.action;
        this._handleFlowUiMessages(flowResponse);
      } catch (error) {
        this.fullscreenLoader.hide();
        this._handleFlowError(error);
      }
    },
    _handleFlowError(flowResp) {
      if (flowResp.error.code === 410) {
        window.location.href = API_ORY_URL + 'self-service/login/browser?refresh=true';
      } else {
        return this.$router.push('/login');
      }
    },
    _handleFlowUiMessages(flowResp) {
      if (!flowResp.ui?.messages) return;
      const EMAIL_OR_PASSWORD_INCORRECT = flowResp.ui.messages[0].id == 4000006;
      if (EMAIL_OR_PASSWORD_INCORRECT)
        this.loginErrorMessage = 'The email or password you entered is incorrect. Please enter the correct credentials and try again.';
    },
    _showAlertsAndClearState() {
      if (LSS.get("verified")) this.successAlertMessage = 'Email verification successful. Please login.';
      if (LSS.get("joinedTeam")) this.successAlertMessage = 'Joined the team successfully. Please login.';

      localStorage.removeItem("verified");
      localStorage.removeItem("joinedTeam");
    }
  },
  created() {
    this.loginFormService = new LoginFormService();
  },
  beforeMount() {
    const flow = this.$route.query.flow;
    if (!flow) return this.$router.push('/login');
    this.getFlow();

    this._showAlertsAndClearState();
  }
});
