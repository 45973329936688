


















// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
import SignupForm from '../SignupForm/SignupForm.vue';
import LoginForm from '../LoginForm/LoginForm.vue';

export default Vue.extend({
  name: 'Entry',
  components: {
    SignupForm,
    LoginForm
  },
  data() {
    return {
      authTabModel: null
    };
  },
  props: {

  },
  methods: {
    switchToLogin() {
      this.authTabModel = 0;
    },
    switchToSignup() {
      this.authTabModel = 1;
    }
  },
  mounted() {
    if(this.$route.query.id) this.switchToSignup();
    if(this.$route.query.signup) this.switchToSignup();
  }
});
