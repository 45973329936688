class LoginFormService {
    emailRules = [
        (v) => !!v || 'Email is required',
        (v) =>
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                v
            ) || 'Email must be valid',
        (v) => !/\s/.test(v) || 'Email cannot contain spaces',
    ];

    passwordRules = [(v) => !!v || 'Password is required', (v) => (v && v.length >= 6) || 'Password must be at least 6 characters'];
}

export default LoginFormService;
