var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-alert",
    {
      staticClass: "success-alert mt-5",
      attrs: {
        value: !!_vm.localMessage,
        dismissible: "",
        "close-icon": "mdi-close",
        icon: "mdi-check-circle",
        color: "#212121",
        dark: "",
      },
    },
    [_c("span", [_vm._v(_vm._s(_vm.localMessage))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }