













// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';

export default Vue.extend({
  name: 'SuccessAlert',
  data() {
    return {
      localMessage: '',
    };
  },
  props: {
    successMessage: '',
  },
  watch: {
    successMessage(value) {
      if (value) {
        this.localMessage = value;
        this._hideAfterTimeout();
      }
    },
  },
  methods: {
    _hideAfterTimeout() {
      if (this.localMessage) setTimeout(() => (this.localMessage = ''), 4000);
    },
  },
  beforeMount() {
    this.localMessage = this.successMessage;
    this._hideAfterTimeout();
  },
});
