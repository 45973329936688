var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-tabs",
        {
          attrs: { grow: "" },
          model: {
            value: _vm.authTabModel,
            callback: function ($$v) {
              _vm.authTabModel = $$v
            },
            expression: "authTabModel",
          },
        },
        [
          _c("v-tab", [
            _c("span", { staticClass: "gotham-medium-font" }, [
              _vm._v("LOG IN"),
            ]),
          ]),
          _c("v-tab", [
            _c("span", { staticClass: "gotham-medium-font" }, [
              _vm._v("SIGN UP"),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.authTabModel,
            callback: function ($$v) {
              _vm.authTabModel = $$v
            },
            expression: "authTabModel",
          },
        },
        [
          _c("v-tab-item", [_c("LoginForm")], 1),
          _c("v-tab-item", [_c("SignupForm")], 1),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }